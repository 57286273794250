/** Classes for the displayed toast **/
// .Toastify__toast {
// }
// .Toastify__toast--rtl {
// }
// .Toastify__toast--dark {
// }
// .Toastify__toast--default {
// }
// .Toastify__toast--info {
// }
.Toastify__toast--success {
  background: $green;
}
.Toastify__toast--warning {
  background: $yellow;
}
.Toastify__toast--error {
  background: $red;
}
// .Toastify__toast-body {
// }
